import { urls } from '@/constants';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { rules } from '@/constants';
import { ProfileCompletionStatus } from '@/domains/me';
import { hasRole } from '@/permission';

import ConsumeRedirectRoute from '@flyblack/common/components/Navigation/ConsumeRedirectRoute';

import { SessionContext } from '@/components/Context/Session';

import UserProfile from '@/page/Home/UserProfile/Base';
import EditUserProfile from '@/page/Home/UserProfile/Edit';
import JetDeals from '@/page/Home/JetDeals';
import JetDealDetail from '@/page/Home/JetDealDetail';
import Statistics from '@/page/Home/Statistics';

export interface Props {}

const Main: React.FC<Props> = () => {
  const { loading, me } = React.useContext(SessionContext);

  return (
    <main className="flex-1 relative overflow-y-auto focus:outline-none" tabIndex={0}>
      {!loading && me.profileCompletionStatus === ProfileCompletionStatus.Incomplete ? (
        <Switch>
          <Route path={urls.profile.edit} component={EditUserProfile} />

          <ConsumeRedirectRoute render={() => <Redirect to={urls.profile.edit} />} />
        </Switch>
      ) : (
        <Switch>
          <Route path={urls.jetDeals.create} component={JetDealDetail} />

          <Route path={urls.jetDeals.detail.base} component={JetDealDetail} />

          <Route path={urls.jetDeals.base} component={JetDeals} />

          <Route path={urls.profile.edit} component={EditUserProfile} />

          <Route path={urls.profile.base} component={UserProfile} />

          {hasRole(me.role, rules.STATISTICS_VIEW) && <Route path={urls.statistics.base} component={Statistics} />}

          <ConsumeRedirectRoute render={() => <Redirect to={urls.jetDeals.base} />} />
        </Switch>
      )}
    </main>
  );
};

export default Main;
