import React from 'react';

import { getRevenue } from '@/services/api/reports';

import useLoad from '@flyblack/common/hooks/useLoad';

import Spacer from '@flyblack/common/components/Spacer';
import Container from '@flyblack/common/components/Container';
import RowContainer from '@flyblack/common/components/RowContainer';

import {
  ActiveDealsCard,
  BookedDealsCard,
  HoursFlownCard,
  RevenueCard,
  RevenueChartCard
} from '@/components/StatisticsCards';

const Statistics = () => {
  const loadData = useLoad({
    load: getRevenue
  });

  return (
    <div className="bg-black min-h-full">
      <Spacer xs={3} />

      <Container max>
        <RowContainer>
          <RowContainer.Item col={8}>
            <RowContainer>
              <RowContainer.Item col={4}>
                <ActiveDealsCard />
              </RowContainer.Item>

              <RowContainer.Item col={4}>
                <BookedDealsCard />
              </RowContainer.Item>

              <RowContainer.Item col={4}>
                <HoursFlownCard />
              </RowContainer.Item>
            </RowContainer>
          </RowContainer.Item>

          <RowContainer.Item col={4}>
            <RevenueCard disableShowMore {...loadData} />
          </RowContainer.Item>
        </RowContainer>

        <RevenueChartCard {...loadData} />
      </Container>
    </div>
  );
};

export default Statistics;
