import { UpdatePassword } from '@flyblack/common/domains';
import { authorizedApi } from '@/services/network/authorized-api';

import { VendorMe } from '@/domains/me';

export const getMe = (): Promise<VendorMe> =>
  authorizedApi.get<{ user: VendorMe }>(`/v1/users/me`).then(({ user, ...rest }) => ({
    ...user,
    ...rest
  }));

export const updatePassword = (data: UpdatePassword): Promise<any> => authorizedApi.put(`/v1/users/me/password`, data);

export const updateMe: (data: VendorMe) => Promise<any> = (data) => authorizedApi.put<VendorMe>(`/v1/users/me`, data);
