import React from 'react';

import Icon from '@flyblack/common/components/Icon';

interface Props {
  children: React.ReactElement;
  showWarning: boolean;
}

const FieldWithMissingInfo = ({ children, showWarning }: Props) => {
  return (
    <div className="flex items-end">
      <div className="flex-auto">{children}</div>
      {showWarning && <Icon type="attention" appearance="red" className="mb-4" />}
    </div>
  );
};

export default FieldWithMissingInfo;
