import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { cancelEmptyLeg, deleteEmptyLeg } from '@/services/api/empty-leg';

import Button from '@flyblack/common/components/Button';
import Icon from '@flyblack/common/components/Icon';
import Typography from '@flyblack/common/components/Typography';

interface Props {
  formId: string;
  jetDealId?: number;
  submitting: boolean;
  disabledSubmit: boolean;
  showDelete?: boolean;
  allowDelete?: boolean;
}

const JetDealDetailButtons: React.FC<Props> = ({
  formId,
  jetDealId,
  submitting,
  disabledSubmit,
  showDelete,
  allowDelete
}) => {
  const history = useHistory();

  const [loadingOnDelete, setLoadingOnDelete] = React.useState(false);

  const deleteJetDeal = () => {
    setLoadingOnDelete(true);
    return deleteEmptyLeg(jetDealId)
      .then(() => history.push(urls.jetDeals.base))
      .catch(() => setLoadingOnDelete(false));
  };

  const cancelJetDeal = () => {
    setLoadingOnDelete(true);
    return cancelEmptyLeg(jetDealId)
      .then(() => history.push(urls.jetDeals.base))
      .catch(() => setLoadingOnDelete(false));
  };

  return (
    <div className="flex justify-end">
      {jetDealId && showDelete && (
        <Button
          appearance="transparent"
          className="w-[200px] mr-6"
          form={formId}
          loading={loadingOnDelete}
          type="button"
          onClick={allowDelete ? deleteJetDeal : cancelJetDeal}
        >
          <Icon type="bin" className="pr-2" />
          <Typography is="span" type="halcyon">
            <FormattedMessage id={translations.inputs.buttons[allowDelete ? 'deleteJetDeal' : 'cancelJetDeal']} />
          </Typography>
        </Button>
      )}

      <Button
        onClick={() => history.push(urls.jetDeals.base)}
        appearance="ghost"
        className="w-[128px] mr-6"
        form={formId}
        type="button"
      >
        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.inputs.buttons.discardJetDeal} />
        </Typography>
      </Button>

      <Button
        appearance="white"
        className="w-[200px]"
        form={formId}
        loading={submitting}
        disabled={disabledSubmit}
        type="submit"
      >
        <Typography is="span" type="halcyon">
          <FormattedMessage id={translations.inputs.buttons[jetDealId ? 'updateJetDeal' : 'addJetDeal']} />
        </Typography>
      </Button>
    </div>
  );
};

export default JetDealDetailButtons;
