import React from 'react';
import { FormatDateOptions, FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { EmptyLegListItem, FixedSchedule, FlexibleSchedule, ScheduleType, Status } from '@flyblack/common/domains';

import Icon from '@flyblack/common/components/Icon';
import Table from '@flyblack/common/components/Table';
import Tooltip from '@flyblack/common/components/Tooltip';
import Typography from '@flyblack/common/components/Typography';

import MissingDataTooltip from './MissingDataTooltip';
import JetDealTableItemMenu from './JetDealTableItemMenu';

const formatDateOptions: FormatDateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: 'numeric',
  hour12: true
};

const hasMissingInfo = (emptyLeg: EmptyLegListItem) => {
  return (
    (emptyLeg.status === Status.Booked || emptyLeg.status === Status.PendingBooking) &&
    !(
      emptyLeg.pilots &&
      emptyLeg.pilots.length === 2 &&
      emptyLeg.pilots[0] &&
      emptyLeg.pilots[1] &&
      emptyLeg.tailNumber &&
      emptyLeg.sourceAirport.terminal &&
      emptyLeg.destinationAirport.terminal &&
      emptyLeg.aircraftType
    )
  );
};

export interface Props {
  data: EmptyLegListItem;
  onAction: () => any;
}

const JetDealTableItem: React.FC<Props> = ({ data, onAction }) => {
  const intl = useIntl();
  const history = useHistory();

  const getSchedule = (schedule: FixedSchedule | FlexibleSchedule, schedulePhase: 'departure' | 'arrival') => {
    return schedule.type === ScheduleType.Fixed
      ? schedulePhase === 'departure'
        ? intl.formatDate(schedule.departsAt, formatDateOptions)
        : intl.formatDate(schedule.arrivesAt, formatDateOptions)
      : schedulePhase === 'departure'
      ? `${intl.formatDate(schedule.departureInterval.start, formatDateOptions)} - ${intl.formatDate(
          schedule.departureInterval.end,
          formatDateOptions
        )}`
      : `${intl.formatDate(schedule.arrivalInterval.start, formatDateOptions)} - ${intl.formatDate(
          schedule.arrivalInterval.end,
          formatDateOptions
        )}`;
  };

  const onItemClick = () => history.push(urls.jetDeals.detail.get(data.id));

  return (
    <Typography
      onClick={onItemClick}
      is={Table.Row}
      className="justify-between font-light h-20 px-6 odd:bg-flyblack-gray"
      type="hummingbird"
      hoverable
    >
      <Table.Data className="w-[8%]">
        {hasMissingInfo(data) && (
          <Tooltip content={MissingDataTooltip} className="mr-2">
            <Icon type="attention" appearance="red" />
          </Tooltip>
        )}
        <FormattedMessage id={translations.domains.status[data.status]} />
      </Table.Data>

      <Table.Data className="pr-1 w-[10%]">{`${data.sourceAirport.displayCode} ${data.sourceAirport.city.name}`}</Table.Data>

      <Table.Data className="pr-1 w-[22%]">{getSchedule(data.schedule, 'departure')}</Table.Data>

      <Table.Data className="pr-1 w-[10%]">{`${data.destinationAirport.displayCode} ${data.destinationAirport.city.name}`}</Table.Data>

      <Table.Data className="pr-1 w-[22%]">{getSchedule(data.schedule, 'arrival')}</Table.Data>

      <Table.Data className="w-[8%]">
        {data.aircraftType ? data.aircraftType.aircraftCategory.name : data.aircraftCategory.name}
      </Table.Data>

      <Table.Data className="w-[10%]"> {data.aircraftType ? data.aircraftType.name : 'TBA'}</Table.Data>

      <Table.Data className="w-[5%] justify-end">{`${data.price.symbol}${Number(
        data.price.value
      ).toLocaleString()}`}</Table.Data>

      <Table.Data className="w-[5%] justify-end" stopPropagation>
        <JetDealTableItemMenu
          jetDealId={data.id}
          isBooked={data.status === Status.Booked || data.status === Status.PendingBooking}
          onAction={onAction}
        />
      </Table.Data>
    </Typography>
  );
};

export default JetDealTableItem;
