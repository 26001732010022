import { Page, EmptyLegListItem, VendorEmptyLeg, Status } from '@flyblack/common/domains';

import { authorizedApi } from '@/services/network/authorized-api';

export const createEmptyLeg = (data: any) => authorizedApi.post<string>(`/v1/empty-legs`, data);

export const updateEmptyLeg = (data: any, id: number) => authorizedApi.put<string>(`/v1/empty-legs/${id}`, data);

export const getEmptyLegs = (
  pageNumber,
  pageSize,
  options?: {
    [x: string]: any;
  }
) => authorizedApi.get<Page<EmptyLegListItem>>(`/v1/empty-legs`, { pageNumber, pageSize, ...options });

export const getVendorEmptyLeg = (id: number) => authorizedApi.get<VendorEmptyLeg>(`/v1/vendor/empty-legs/${id}`);

export const deleteEmptyLeg = (id) => authorizedApi.delete<Page<any>>(`/v1/empty-legs/${id}`);

export const cancelEmptyLeg = (id) => authorizedApi.put(`/v1/empty-legs/${id}/status`, { status: Status.Canceled });
