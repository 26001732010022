import * as yup from 'yup';

import { translations } from '@/locale';

export default yup.object({
  sourceAirportId: yup
    .number()
    .label(translations.inputs.departureLocationSelect.label)
    .test('differentThanDestination', translations.validation.custom.identicalLocations, function(value) {
      const destinationAirportId: number = this.resolve(yup.ref('destinationAirportId'));

      return !destinationAirportId ? true : destinationAirportId !== value;
    })
    .required(),
  sourceAirportTerminal: yup
    .string()
    .nullable()
    .notRequired(),
  destinationAirportId: yup
    .number()
    .label(translations.inputs.destinationLocationSelect.label)
    .test('differentThanSource', translations.validation.custom.identicalLocations, function(value) {
      const sourceAirportId: number = this.resolve(yup.ref('sourceAirportId'));

      return !sourceAirportId ? true : sourceAirportId !== value;
    })
    .required(),
  destinationAirportTerminal: yup
    .string()
    .nullable()
    .notRequired(),
  price: yup
    .number()
    .positive()
    .label(translations.inputs.price.errorLabel)
    .required(),
  tailNumber: yup
    .string()
    .nullable()
    .notRequired(),
  firstPilot: yup
    .string()
    .nullable()
    .notRequired(),
  secondPilot: yup
    .string()
    .nullable()
    .notRequired(),
  isFixedSchedule: yup.boolean().required(),
  departureWindowStartDate: yup.string().when('isFixedSchedule', {
    is: false,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  departureWindowStartTime: yup.string().when('isFixedSchedule', {
    is: false,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  departureWindowEndDate: yup.string().when('isFixedSchedule', {
    is: false,
    then: yup
      .string()
      .test('minValueDepartureDates', translations.validation.custom.dateInterval, function() {
        const startDate: string = this.resolve(yup.ref('departureWindowStartDate'));
        const endDate: string = this.resolve(yup.ref('departureWindowEndDate'));

        if (!startDate || !endDate) return true;

        return startDate.localeCompare(endDate) <= 0;
      })
      .required(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  departureWindowEndTime: yup.string().when('isFixedSchedule', {
    is: false,
    then: yup
      .string()
      .test('minValueDepartureTimes', translations.validation.custom.timeInterval, function() {
        const startDate: string = this.resolve(yup.ref('departureWindowStartDate'));
        const startTime: string = this.resolve(yup.ref('departureWindowStartTime'));
        const endTime: string = this.resolve(yup.ref('departureWindowEndTime'));
        const endDate: string = this.resolve(yup.ref('departureWindowEndDate'));

        if (!startDate || !endDate || !startTime) return true;

        return startDate === endDate ? startTime.localeCompare(endTime) !== 1 : true;
      })
      .required(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  arrivalWindowStartDate: yup.string().when('isFixedSchedule', {
    is: false,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  arrivalWindowStartTime: yup.string().when('isFixedSchedule', {
    is: false,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  arrivalWindowEndDate: yup.string().when('isFixedSchedule', {
    is: false,
    then: yup
      .string()
      .test('minValueArrivalDates', translations.validation.custom.dateInterval, function() {
        const startDate: string = this.resolve(yup.ref('arrivalWindowStartDate'));
        const endDate: string = this.resolve(yup.ref('arrivalWindowEndDate'));

        if (!startDate || !endDate) return true;

        return startDate.localeCompare(endDate) <= 0;
      })
      .required(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  arrivalWindowEndTime: yup.string().when('isFixedSchedule', {
    is: false,
    then: yup
      .string()
      .test('minValueArrivalTimes', translations.validation.custom.timeInterval, function() {
        const startDate: string = this.resolve(yup.ref('arrivalWindowStartDate'));
        const startTime: string = this.resolve(yup.ref('arrivalWindowStartTime'));
        const endTime: string = this.resolve(yup.ref('arrivalWindowEndTime'));
        const endDate: string = this.resolve(yup.ref('arrivalWindowEndDate'));

        if (!startDate || !endDate || !startTime) return true;

        return startDate === endDate ? startTime.localeCompare(endTime) !== 1 : true;
      })
      .required(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  departureDate: yup.string().when('isFixedSchedule', {
    is: true,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  departureTime: yup.string().when('isFixedSchedule', {
    is: true,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  arrivalDate: yup.string().when('isFixedSchedule', {
    is: true,
    then: yup
      .string()
      .test('minValueArrivalDate', translations.validation.custom.arrivalDate, function() {
        const departureDate: string = this.resolve(yup.ref('departureDate'));
        const arrivalDate: string = this.resolve(yup.ref('arrivalDate'));

        if (!departureDate || !arrivalDate) return true;

        return departureDate.localeCompare(arrivalDate) <= 0;
      })
      .required(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  arrivalTime: yup.string().when('isFixedSchedule', {
    is: true,
    then: yup
      .string()
      .test('minValueArrivalTime', translations.validation.custom.arrivalTime, function() {
        const departureDate: string = this.resolve(yup.ref('departureDate'));
        const departureTime: string = this.resolve(yup.ref('departureTime'));
        const arrivalDate: string = this.resolve(yup.ref('arrivalDate'));
        const arrivalTime: string = this.resolve(yup.ref('arrivalTime'));

        if (!departureDate || !arrivalDate || !departureTime) return true;

        return departureDate === arrivalDate ? departureTime.localeCompare(arrivalTime) !== 1 : true;
      })
      .required(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  aircraftCategory: yup.string().required(),
  aircraftType: yup
    .number()
    .nullable()
    .notRequired()
});
