import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { urls } from '@/constants';
import { RevenueReport } from '@flyblack/common/domains';

import { Return as LoadReturn } from '@flyblack/common/hooks/useLoad';

import Loading from '@flyblack/common/components/Loading';
import LargeDataDetail from '@flyblack/common/components/LargeDataDetail';
import RowContainer from '@flyblack/common/components/RowContainer';
import DetailCard from '@flyblack/common/components/DetailCard';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';
import Icon from '@flyblack/common/components/Icon';

const REVENUE_CURRENCY_SYMBOL = '$';

interface Props extends LoadReturn<RevenueReport> {
  disableShowMore?: boolean;
}

const RevenueCard = ({ disableShowMore = false, value: revenueReport, loading, error }: Props) => {
  const intl = useIntl();
  const history = useHistory();

  const currentDate = new Date();
  const [currentYear, currentMonth] = [currentDate.getFullYear(), currentDate.getMonth() + 1];

  return (
    <DetailCard>
      {loading ? (
        <Loading visible={loading} className="py-9" center>
          <Loading.Indicator size={40} borderWidth={2} />
        </Loading>
      ) : (
        <RowContainer>
          <RowContainer.Item col={6}>
            <LargeDataDetail
              title={intl.formatMessage(
                { id: translations.pages.jetDeals.reports.revenue },
                { month: intl.formatDate(new Date(currentYear, currentMonth - 1), { month: 'long' }) }
              )}
              data={
                error
                  ? intl.formatMessage({ id: translations.pages.jetDeals.reports.errorValue })
                  : intl.formatMessage(
                      { id: translations.pages.jetDeals.reports.revenuePrice },
                      {
                        symbol: REVENUE_CURRENCY_SYMBOL,
                        value:
                          revenueReport.emptyLegsRevenue.data[
                            revenueReport.periods.findIndex(
                              (period) => period.year === currentYear && period.periodOfYear === currentMonth
                            )
                          ]
                      }
                    )
              }
            />
          </RowContainer.Item>

          {!disableShowMore && (
            <RowContainer.Item col={6} className="flex items-end">
              <Button
                onClick={() => history.push(urls.statistics.base)}
                appearance="transparent"
                fat
                className="w-full"
              >
                <Typography is="div" type="halcyon" className="font-normal">
                  <FormattedMessage id={translations.pages.jetDeals.reports.seeMoreButton} />
                  <Icon type="forward" />
                </Typography>
              </Button>
            </RowContainer.Item>
          )}
        </RowContainer>
      )}
    </DetailCard>
  );
};

export default RevenueCard;
