import React from 'react';
import * as yup from 'yup';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { updateMe } from '@/services/api/me';
import { STRING_LONG_MAX_LENGTH } from '@/constants';

import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

import { SessionContext } from '@/components/Context/Session';

const TextInputWithValidation = withValidation(TextInput);

const schema = yup.object({
  firstName: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.firstName.label)
    .required(),
  lastName: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.lastName.label)
    .required(),
  email: yup
    .string()
    .email()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.email.label)
    .required(),
  companyName: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.companyName.label)
    .required(),
  companyAddress: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.companyAddress.label)
    .required(),
  phoneNumber: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.phoneNumber.label)
    .required(),
  bankName: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.bankName.label)
    .nullable()
    .notRequired(),
  bankAccountNumber: yup
    .string()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.bankAccount.label)
    .nullable()
    .notRequired()
});

const EditUserProfile: React.FC = () => {
  const { me, loading, reload } = React.useContext(SessionContext);

  const intl = useIntl();

  return (
    !loading && (
      <div className="bg-black min-h-full">
        <Spacer xs={3} sm={10} />

        <div className="text-sm font-normal text-flyblack-light-gray text-center">
          <FormattedMessage id={translations.pages.profile.edit.title} />
        </div>

        <Spacer xs={2} />

        <Form
          id="user"
          schema={schema}
          subscription={{ errors: true, submitting: true, submitError: true, dirty: true, values: true, valid: true }}
          initialValues={{
            ...me,
            companyName: me.vendor.name,
            companyAddress: me.vendor.address,
            bankName: me.vendor.paymentInfo && me.vendor.paymentInfo.bankName,
            bankAccountNumber: me.vendor.paymentInfo && me.vendor.paymentInfo.account
          }}
          onSubmit={({ companyName, companyAddress, bankAccountNumber, bankName, ...rest }) =>
            updateMe({
              ...rest,
              id: me.id,
              vendor: {
                name: companyName,
                address: companyAddress,
                paymentInfo: {
                  account: bankAccountNumber,
                  bankName: bankName
                }
              }
            }).then(reload)
          }
        >
          {({ submitError, submitting, values, valid, form, errors, dirty }, formId) => (
            <div className="flex justify-center">
              <div className="flex flex-col p-5 w-full max-w-[420px]">
                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${formId}-company-name`}
                  name="companyName"
                  label={intl.formatMessage({ id: translations.inputs.companyName.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.companyName.placeholder })}
                  readOnly={submitting}
                />

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${formId}-first-name`}
                  name="firstName"
                  type="text"
                  label={intl.formatMessage({ id: translations.inputs.firstName.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.firstName.placeholder })}
                  readOnly={submitting}
                />

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${formId}-last-name`}
                  name="lastName"
                  type="text"
                  label={intl.formatMessage({ id: translations.inputs.lastName.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.lastName.placeholder })}
                  readOnly={submitting}
                />

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  id={`${formId}-email`}
                  name="email"
                  type="text"
                  label={intl.formatMessage({ id: translations.inputs.email.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                  readOnly={submitting}
                  disabled
                />

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${formId}-phone-number`}
                  name="phoneNumber"
                  label={intl.formatMessage({ id: translations.inputs.phoneNumber.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.phoneNumber.placeholder })}
                  readOnly={submitting}
                />

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${formId}-company-address`}
                  name="companyAddress"
                  label={intl.formatMessage({ id: translations.inputs.companyAddress.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.companyAddress.placeholder })}
                  readOnly={submitting}
                />

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${formId}-bank-name`}
                  name="bankName"
                  label={intl.formatMessage({ id: translations.inputs.bankName.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.bankName.placeholder })}
                  readOnly={submitting}
                />

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  type="text"
                  id={`${formId}-bank-account-number`}
                  name="bankAccountNumber"
                  label={intl.formatMessage({ id: translations.inputs.bankAccount.label })}
                  placeholder={intl.formatMessage({ id: translations.inputs.bankAccount.placeholder })}
                  readOnly={submitting}
                />

                <Spacer xs={4} />

                <div
                  className={classnames(
                    'flex fixed right-0 top-20 w-full p-4 transition-opacity duration-500 justify-between max-w-[320px]',
                    dirty ? 'opacity-100' : 'opacity-0'
                  )}
                >
                  <Button
                    appearance="ghost"
                    fat
                    form={formId}
                    onClick={() => form.reset()}
                    type="button"
                    className="w-full mr-2"
                  >
                    <Typography is="span" type="halcyon">
                      <FormattedMessage id={translations.inputs.buttons.cancel} />
                    </Typography>
                  </Button>

                  <Button
                    appearance="white"
                    fat
                    className="w-full ml-2"
                    form={formId}
                    loading={submitting}
                    disabled={!dirty || !valid}
                    type="submit"
                  >
                    <Typography is="span" type="halcyon">
                      <FormattedMessage id={translations.inputs.buttons.save} />
                    </Typography>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Form>

        <Spacer xs={10} />
      </div>
    )
  );
};

export default EditUserProfile;
