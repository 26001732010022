import React from 'react';
import { useIntl } from 'react-intl';

import { translations } from '@/locale';
import { getBookedFlights } from '@/services/api/reports';

import useLoad from '@flyblack/common/hooks/useLoad';

import Loading from '@flyblack/common/components/Loading';
import LargeDataDetail from '@flyblack/common/components/LargeDataDetail';
import DetailCard from '@flyblack/common/components/DetailCard';

const BookedDealsCard = () => {
  const intl = useIntl();

  const { value: bookedFlightsReport, loading, error } = useLoad({
    load: getBookedFlights
  });

  return (
    <DetailCard>
      {loading ? (
        <Loading visible={loading} className="py-9" center>
          <Loading.Indicator size={40} borderWidth={2} />
        </Loading>
      ) : (
        <LargeDataDetail
          title={intl.formatMessage({ id: translations.pages.jetDeals.reports.bookedDeals })}
          data={
            error
              ? intl.formatMessage({ id: translations.pages.jetDeals.reports.errorValue })
              : bookedFlightsReport.vendorEmptyLegs
          }
        />
      )}
    </DetailCard>
  );
};

export default BookedDealsCard;
