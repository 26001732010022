import React from 'react';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Icon from '@flyblack/common/components/Icon';
import Card from '@flyblack/common/components/Card';
import Link from '@flyblack/common/components/Link';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';

import { SessionContext } from '@/components/Context/Session';

const UserProfile: React.FC = () => {
  const { me, signOut, loading } = React.useContext(SessionContext);

  const history = useHistory();

  return (
    !loading && (
      <div className="bg-black min-h-full">
        <div className="flex flex-col sm:flex-row">
          <Card.Column padded={false} className="sm:w-1/2">
            <div className="bg-white w-full flex flex-col items-center sm:max-w-xs sm:absolute sm:inset-y-0 sm:left-0 md:max-w-[375px]">
              <Spacer xs={2} />

              <Link to={urls.profile.edit} className="text-black self-start px-4">
                <Icon type="edit" />
              </Link>

              <Spacer xs={4} />

              {me.firstName && me.lastName && (
                <Typography is="span" type="halcyon" className="text-black">
                  {`${me.firstName} ${me.lastName}`}
                </Typography>
              )}

              <Typography is="span" type="hummingbird" color="lightGray">
                {`${me.email}`}
              </Typography>

              <Spacer xs={2} />
            </div>
          </Card.Column>

          <Card.Column padded={false} className="sm:w-1/2">
            <div className="flex flex-col max-w-[400px] mt-8 p-4 mx-auto space-y-8 sm:mx-0 md:mt-20">
              <Button
                url={urls.home}
                appearance="transparent"
                className="w-32"
                type="button"
                onClick={() => signOut().then(() => history.push(urls.home))}
              >
                <Icon type="logOut" className="mr-4" />

                <FormattedMessage id={translations.inputs.buttons.logOut} />
              </Button>

              <Spacer xs={10} />
            </div>
          </Card.Column>
        </div>
      </div>
    )
  );
};

export default UserProfile;
