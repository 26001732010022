import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { urls } from '@/constants';
import { translations } from '@/locale';
import { getAirports } from '@/services/api/airport';
import { getAircraftCategories } from '@/services/api/aircraft-type';
import { createEmptyLeg, getVendorEmptyLeg, updateEmptyLeg } from '@/services/api/empty-leg';

import { getDateFormat, getNow, getTime } from '@flyblack/common/util';
import { ScheduleType, Status, VendorEmptyLeg, Airport } from '@flyblack/common/domains';

import Form from '@flyblack/common/components/Form';
import Spacer from '@flyblack/common/components/Spacer';
import DateTime from '@flyblack/common/components/DateTime';
import DetailRow from '@flyblack/common/components/DetailRow';
import Container from '@flyblack/common/components/Container';
import DetailCard from '@flyblack/common/components/DetailCard';
import Typography from '@flyblack/common/components/Typography';
import Condition from '@flyblack/common/components/Form/Condition';
import ToggleSwitch from '@flyblack/common/components/ToggleSwitch';
import RowContainer from '@flyblack/common/components/RowContainer';
import LoadableSelect from '@flyblack/common/components/LoadableSelect';
import { withSource } from '@flyblack/common/components/hoc/withSource';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { dateFormat } from '@flyblack/common/components/Form/Input/DateInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';
import { SubmitError } from '@flyblack/common/components/Error';
import PassengersSection from '@flyblack/common/components/PassengersSection';

import useLoad from '@flyblack/common/hooks/useLoad';

import AircraftTypeSelect from '@/components/AircraftTypeSelect';

import jetDealDetailSchema from './JetDealDetailSchema';
import DateTimeInterval from './DateTimeInterval';

import JetDealDetailButtons from './JetDealDetailButtons';
import FieldWithMissingInfo from './FieldWithMissingInfo';

const DEFAULT_CURRENCY = 'USD';

export interface Props {}

interface Params {
  id: string;
}

const TextInputWithValidation = withValidation(TextInput);

const AirportSelectWithValidation = withValidation(
  withSource<Airport[]>(LoadableSelect, () =>
    getAirports().then((reponse) =>
      reponse.map((item) => ({
        id: item.id,
        name: `${item.faaCode} ${item.city.name}`
      }))
    )
  )
);

const AircraftTypeSelectWithValidation = withValidation(AircraftTypeSelect);

const AircraftCategorySelectWithValidation = withValidation(
  withSource(LoadableSelect, () => getAircraftCategories().then((reponse) => reponse.categories))
);

const loadEmptyLeg = (id?: number) =>
  id ? getVendorEmptyLeg(id) : new Promise<VendorEmptyLeg>((resolve) => resolve(null));

const JetDealDetail: React.FC<Props & RouteComponentProps<Params>> = ({ match }) => {
  const intl = useIntl();
  const history = useHistory();

  const { value: data, loading, error } = useLoad({
    load: loadEmptyLeg,
    id: parseInt(match.params.id)
  });

  const now = getNow(dateFormat);
  const initialDateTimeValues = data
    ? data.schedule.type === ScheduleType.Fixed
      ? {
          departureDate: getDateFormat(data.schedule.departsAt, dateFormat),
          departureTime: getTime(data.schedule.departsAt),
          arrivalDate: getDateFormat(data.schedule.arrivesAt, dateFormat),
          arrivalTime: getTime(data.schedule.arrivesAt),
          departureWindowStartDate: getDateFormat(data.schedule.departsAt, dateFormat),
          departureWindowStartTime: getTime(data.schedule.departsAt),
          departureWindowEndDate: getDateFormat(data.schedule.departsAt, dateFormat),
          departureWindowEndTime: getTime(data.schedule.departsAt),
          arrivalWindowStartDate: getDateFormat(data.schedule.arrivesAt, dateFormat),
          arrivalWindowStartTime: getTime(data.schedule.arrivesAt),
          arrivalWindowEndDate: getDateFormat(data.schedule.arrivesAt, dateFormat),
          arrivalWindowEndTime: getTime(data.schedule.arrivesAt)
        }
      : {
          departureDate: getDateFormat(data.schedule.departureInterval.start, dateFormat),
          departureTime: getTime(data.schedule.departureInterval.start),
          arrivalDate: getDateFormat(data.schedule.arrivalInterval.start, dateFormat),
          arrivalTime: getTime(data.schedule.arrivalInterval.start),
          departureWindowStartDate: getDateFormat(data.schedule.departureInterval.start, dateFormat),
          departureWindowStartTime: getTime(data.schedule.departureInterval.start),
          departureWindowEndDate: getDateFormat(data.schedule.departureInterval.end, dateFormat),
          departureWindowEndTime: getTime(data.schedule.departureInterval.end),
          arrivalWindowStartDate: getDateFormat(data.schedule.arrivalInterval.start, dateFormat),
          arrivalWindowStartTime: getTime(data.schedule.arrivalInterval.start),
          arrivalWindowEndDate: getDateFormat(data.schedule.arrivalInterval.end, dateFormat),
          arrivalWindowEndTime: getTime(data.schedule.arrivalInterval.end)
        }
    : {
        departureDate: now,
        departureTime: null,
        arrivalDate: now,
        arrivalTime: null,
        departureWindowStartDate: now,
        departureWindowStartTime: null,
        departureWindowEndDate: now,
        departureWindowEndTime: null,
        arrivalWindowStartDate: now,
        arrivalWindowStartTime: null,
        arrivalWindowEndDate: now,
        arrivalWindowEndTime: null
      };

  const isBooked = data && (data.status === Status.Booked || data.status === Status.PendingBooking);

  const setCustomError = (errorObject) =>
    errorObject.code === 'empty_leg_value_too_big' ? errorObject.developerMessage : null;

  return (
    <div className="min-h-full py-20">
      <Container max>
        <Form
          id="new-participant"
          schema={jetDealDetailSchema}
          subscription={{
            errors: true,
            submitting: true,
            submitError: true,
            dirty: true,
            values: true,
            hasValidationErrors: true
          }}
          initialValues={{
            sourceAirportId: data ? data.sourceAirport.id : null,
            sourceAirportTerminal: data ? data.sourceAirport.terminal : '',
            destinationAirportId: data ? data.destinationAirport.id : null,
            destinationAirportTerminal: data ? data.destinationAirport.terminal : '',
            price: data ? data.price.value : null,
            aircraftCategory: data
              ? data.aircraftType
                ? data.aircraftType.aircraftCategory.id
                : data.aircraftCategory.id
              : null,
            aircraftType: data && data.aircraftType ? data.aircraftType.id : null,
            tailNumber: data ? data.tailNumber : null,
            firstPilot: data && data.pilots.length ? data.pilots[0] : '',
            secondPilot: data && data.pilots.length > 1 ? data.pilots[1] : '',
            isFixedSchedule: data ? data.schedule.type === ScheduleType.Fixed : true,
            ...initialDateTimeValues
          }}
          onSubmit={(values) => {
            const convertedValues = {
              sourceAirportId: values.sourceAirportId,
              sourceAirportTerminal: values.sourceAirportTerminal,
              destinationAirportId: values.destinationAirportId,
              destinationAirportTerminal: values.destinationAirportTerminal,
              aircraftCategoryId: values.aircraftCategory,
              aircraftTypeId: values.aircraftType,
              price: {
                currency: DEFAULT_CURRENCY,
                value: values.price
              },
              tailNumber: values.tailNumber,
              pilots: [values.firstPilot || '', values.secondPilot || ''],
              isFixedSchedule: values.isFixedSchedule,
              schedule: values.isFixedSchedule
                ? {
                    type: ScheduleType.Fixed,
                    departsAt: `${values.departureDate}T${values.departureTime}:00`,
                    arrivesAt: `${values.arrivalDate}T${values.arrivalTime}:00`
                  }
                : {
                    type: ScheduleType.Flexible,
                    departureInterval: {
                      start: `${values.departureWindowStartDate}T${values.departureWindowStartTime}:00`,
                      end: `${values.departureWindowEndDate}T${values.departureWindowEndTime}:00`
                    },
                    arrivalInterval: {
                      start: `${values.arrivalWindowStartDate}T${values.arrivalWindowStartTime}:00`,
                      end: `${values.arrivalWindowEndDate}T${values.arrivalWindowEndTime}:00`
                    }
                  }
            };

            return (data
              ? updateEmptyLeg({ ...convertedValues }, data.id)
              : createEmptyLeg({ ...convertedValues })
            ).then(() => history.push(urls.jetDeals.base));
          }}
        >
          {({ submitError, submitting, values, form, errors, dirty, hasValidationErrors }, formId) => (
            <React.Fragment>
              <div className="flex flex-row justify-between items-center">
                <Typography is="span" type="flamingo">
                  <FormattedMessage
                    id={translations.pages.jetDealDetail.title[data ? 'update' : 'new']}
                    {...(data && { values: { id: data.id } })}
                  />
                </Typography>
              </div>

              <Spacer xs={6} />

              <div className="flex justify-between items-center">
                <Form.Field
                  is={ToggleSwitch}
                  id={`${formId}-is-fixed-schedule`}
                  name="isFixedSchedule"
                  type="checkbox"
                  label={intl.formatMessage({ id: translations.inputs.isFixedSchedule.label })}
                  readOnly={submitting}
                  disabled={isBooked && data.schedule.type === ScheduleType.Fixed}
                />

                <JetDealDetailButtons
                  formId={formId}
                  jetDealId={data ? data.id : null}
                  submitting={submitting}
                  disabledSubmit={!dirty || hasValidationErrors}
                  showDelete
                  allowDelete={!isBooked}
                />
              </div>

              {submitError && (
                <div className="flex flex-col items-end">
                  <Spacer xs={6} />
                  <SubmitError error={submitError} setCustomError={setCustomError} />
                </div>
              )}

              <Spacer xs={4} />

              <DetailRow>
                <DetailCard>
                  <Typography is="span" type="halcyon" weight="bold">
                    <FormattedMessage id={translations.pages.jetDealDetail.sections.departure} />
                  </Typography>

                  <Spacer xs={4} />

                  <Form.Field
                    is={AirportSelectWithValidation}
                    id={`${formId}-source-airport-id`}
                    name="sourceAirportId"
                    type="text"
                    label={intl.formatMessage({ id: translations.inputs.departureLocationSelect.label })}
                    placeholder={intl.formatMessage({
                      id: translations.inputs.departureLocationSelect.placeholder
                    })}
                    readOnly={submitting}
                    disabled={isBooked}
                    leadingIcon="departure"
                  />

                  <Spacer xs={4} />

                  <Condition when="isFixedSchedule" is={true}>
                    <RowContainer>
                      <RowContainer.Item col={6}>
                        <DateTime
                          formId={formId}
                          type="departure"
                          title={intl.formatMessage({ id: translations.inputs.departureDateTime.label })}
                          timePlaceholder={intl.formatMessage({
                            id: translations.inputs.departureDateTime.placeholder
                          })}
                          change={form.change}
                          submitting={submitting}
                          disabledDate={isBooked}
                        />
                      </RowContainer.Item>
                    </RowContainer>
                  </Condition>

                  <Condition when="isFixedSchedule" is={false}>
                    <DateTimeInterval
                      formId={formId}
                      type="departure"
                      title={intl.formatMessage({ id: translations.inputs.flightWindow.labels.departure })}
                      change={form.change}
                      submitting={submitting}
                      disabledDate={isBooked}
                    />
                  </Condition>

                  <Spacer xs={4} />

                  <RowContainer>
                    <RowContainer.Item col={6}>
                      <FieldWithMissingInfo showWarning={isBooked && !values.sourceAirportTerminal}>
                        <Form.Field
                          is={TextInputWithValidation}
                          id={`${formId}-source-airport-terminal`}
                          name="sourceAirportTerminal"
                          type="text"
                          label={intl.formatMessage({ id: translations.inputs.departureAirportTerminal.label })}
                          placeholder={intl.formatMessage({
                            id: translations.inputs.departureAirportTerminal.placeholder
                          })}
                          readOnly={submitting}
                          leadingIcon="terminal"
                        />
                      </FieldWithMissingInfo>
                    </RowContainer.Item>
                  </RowContainer>
                </DetailCard>

                <DetailCard>
                  <Typography is="span" type="halcyon" weight="bold">
                    <FormattedMessage id={translations.pages.jetDealDetail.sections.destination} />
                  </Typography>

                  <Spacer xs={4} />

                  <Form.Field
                    is={AirportSelectWithValidation}
                    id={`${formId}-destination-airport-id`}
                    name="destinationAirportId"
                    type="text"
                    label={intl.formatMessage({ id: translations.inputs.destinationLocationSelect.label })}
                    placeholder={intl.formatMessage({
                      id: translations.inputs.destinationLocationSelect.placeholder
                    })}
                    readOnly={submitting}
                    disabled={isBooked}
                    leadingIcon="destination"
                  />

                  <Spacer xs={4} />

                  <Condition when="isFixedSchedule" is={true}>
                    <RowContainer>
                      <RowContainer.Item col={6}>
                        <DateTime
                          formId={formId}
                          type="arrival"
                          title={intl.formatMessage({ id: translations.inputs.arrivalDateTime.label })}
                          timePlaceholder={intl.formatMessage({ id: translations.inputs.arrivalDateTime.placeholder })}
                          change={form.change}
                          submitting={submitting}
                          disabledDate={isBooked}
                        />
                      </RowContainer.Item>
                    </RowContainer>
                  </Condition>

                  <Condition when="isFixedSchedule" is={false}>
                    <DateTimeInterval
                      formId={formId}
                      type="arrival"
                      title={intl.formatMessage({ id: translations.inputs.flightWindow.labels.arrival })}
                      change={form.change}
                      submitting={submitting}
                      disabledDate={isBooked}
                    />
                  </Condition>

                  <Spacer xs={4} />

                  <RowContainer>
                    <RowContainer.Item col={6}>
                      <FieldWithMissingInfo showWarning={isBooked && !values.destinationAirportTerminal}>
                        <Form.Field
                          is={TextInputWithValidation}
                          id={`${formId}-destination-airport-terminal`}
                          name="destinationAirportTerminal"
                          type="text"
                          label={intl.formatMessage({ id: translations.inputs.destinationAirportTerminal.label })}
                          placeholder={intl.formatMessage({
                            id: translations.inputs.destinationAirportTerminal.placeholder
                          })}
                          readOnly={submitting}
                          leadingIcon="terminal"
                        />
                      </FieldWithMissingInfo>
                    </RowContainer.Item>
                  </RowContainer>
                </DetailCard>
              </DetailRow>

              <DetailRow>
                <DetailCard>
                  <Typography is="span" type="halcyon" weight="bold">
                    <FormattedMessage id={translations.pages.jetDealDetail.sections.aircraftInfo} />
                  </Typography>

                  <Spacer xs={4} />

                  <RowContainer>
                    <RowContainer.Item col={6}>
                      <Form.Field
                        is={AircraftCategorySelectWithValidation}
                        id={`${formId}-aircraft-category`}
                        name="aircraftCategory"
                        type="text"
                        label={intl.formatMessage({ id: translations.inputs.aircraftCategorySelect.label })}
                        placeholder={intl.formatMessage({ id: translations.inputs.aircraftCategorySelect.placeholder })}
                        readOnly={submitting}
                        leadingIcon="plane"
                        onChange={() => form.change('aircraftType', null)}
                      />
                    </RowContainer.Item>

                    <RowContainer.Item col={6}>
                      <FieldWithMissingInfo showWarning={isBooked && !values.aircraftType}>
                        <Form.Field
                          key={values.aircraftCategory}
                          is={AircraftTypeSelectWithValidation}
                          id={`${formId}-aircraft-type`}
                          name="aircraftType"
                          type="text"
                          categoryId={values.aircraftCategory}
                          label={intl.formatMessage({ id: translations.inputs.aircraftTypeSelect.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.aircraftTypeSelect.placeholder })}
                          readOnly={submitting}
                          disabled={values.aircraftCategory === null}
                        />
                      </FieldWithMissingInfo>
                    </RowContainer.Item>
                  </RowContainer>

                  <Spacer xs={4} />

                  <RowContainer>
                    <RowContainer.Item col={6}>
                      <FieldWithMissingInfo showWarning={isBooked && !values.tailNumber}>
                        <Form.Field
                          is={TextInputWithValidation}
                          id={`${formId}-tail-number`}
                          name="tailNumber"
                          type="text"
                          label={intl.formatMessage({ id: translations.inputs.tailNumber.label })}
                          placeholder={intl.formatMessage({ id: translations.inputs.tailNumber.placeholder })}
                          readOnly={submitting}
                        />
                      </FieldWithMissingInfo>
                    </RowContainer.Item>
                  </RowContainer>
                </DetailCard>

                <DetailCard>
                  <Typography is="span" type="halcyon" weight="bold">
                    <FormattedMessage id={translations.pages.jetDealDetail.sections.pilots} />
                  </Typography>

                  <Spacer xs={4} />

                  <FieldWithMissingInfo showWarning={isBooked && !values.firstPilot}>
                    <Form.Field
                      is={TextInputWithValidation}
                      id={`${formId}-first-pilot`}
                      name="firstPilot"
                      type="text"
                      label={intl.formatMessage({ id: translations.inputs.firstPilot.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.firstPilot.placeholder })}
                      readOnly={submitting}
                      leadingIcon="navigationAccount"
                    />
                  </FieldWithMissingInfo>

                  <Spacer xs={4} />

                  <FieldWithMissingInfo showWarning={isBooked && !values.secondPilot}>
                    <Form.Field
                      is={TextInputWithValidation}
                      id={`${formId}-second-pilot`}
                      name="secondPilot"
                      type="text"
                      label={intl.formatMessage({ id: translations.inputs.secondPilot.label })}
                      placeholder={intl.formatMessage({ id: translations.inputs.secondPilot.placeholder })}
                      readOnly={submitting}
                      leadingIcon="navigationAccount"
                    />
                  </FieldWithMissingInfo>
                </DetailCard>
              </DetailRow>

              <DetailRow>
                <DetailCard>
                  <Typography is="span" type="halcyon" weight="bold">
                    <FormattedMessage id={translations.pages.jetDealDetail.sections.price} />
                  </Typography>

                  <Spacer xs={4} />

                  <RowContainer>
                    <RowContainer.Item col={6}>
                      <Form.Field
                        is={TextInputWithValidation}
                        id={`${formId}-price`}
                        name="price"
                        type="text"
                        label={intl.formatMessage(
                          { id: translations.inputs.price.label },
                          { currency: DEFAULT_CURRENCY }
                        )}
                        placeholder={intl.formatMessage({ id: translations.inputs.price.placeholder })}
                        readOnly={submitting}
                        disabled={isBooked}
                        leadingIcon="dollar"
                      />
                    </RowContainer.Item>
                  </RowContainer>
                </DetailCard>

                {data && (data.status === Status.Booked || data.status === Status.PendingBooking) ? (
                  <PassengersSection
                    passengers={data.trip.passengers}
                    pets={data.trip.pets}
                    translations={translations.pages.jetDealDetail.passengersSection}
                  />
                ) : (
                  <DetailCard transparent />
                )}
              </DetailRow>

              {submitError && (
                <div className="flex flex-col items-end">
                  <SubmitError error={submitError} setCustomError={setCustomError} />
                  <Spacer xs={6} />
                </div>
              )}

              <JetDealDetailButtons
                formId={formId}
                jetDealId={data ? data.id : null}
                submitting={submitting}
                disabledSubmit={!dirty || hasValidationErrors}
              />
            </React.Fragment>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default JetDealDetail;
