import React from 'react';
import Helmet from 'react-helmet';
import { withRouter, Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';

import 'tailwindcss/tailwind.css';
import './style/base.sass';

import { urls } from '@/constants';

import Loading from '@flyblack/common/components/Loading';
import ScrollToTop from '@flyblack/common/components/ScrollToTop';
import ConsumeRedirectRoute from '@flyblack/common/components/Navigation/ConsumeRedirectRoute';

import { SessionContext } from './components/Context/Session';

import Home from '@/page/Home';
import Unauthorized from '@/page/Unauthorized';

export interface Props extends RouteComponentProps {}

const App: React.FC<Props> = () => {
  const { loading, session, me } = React.useContext(SessionContext);
  return (
    <ScrollToTop>
      <Helmet defaultTitle="FlyBlack" titleTemplate="%s - FlyBlack" />

      {loading ? (
        <Loading visible={true} className="mt-40" center>
          <Loading.Indicator size={120} borderWidth={2} />
        </Loading>
      ) : (
        <Switch>
          {session && me ? (
            <Route path={urls.home} component={Home} />
          ) : (
            <Route path={urls.home} component={Unauthorized} />
          )}

          <ConsumeRedirectRoute render={() => <Redirect to={urls.jetDeals.base} />} />
        </Switch>
      )}
    </ScrollToTop>
  );
};

export default hot(withRouter(App));
