import React from 'react';
import classnames from 'classnames';

export type Position = 'top' | 'bottom' | 'left' | 'right';
export type Align = 'start' | 'center' | 'end';

interface Props {
  className?: string;
  content: React.ComponentType;
  position?: Position;
  align?: Align;
}

const positionMap: Record<Position, string> = {
  top: 'top-0 -translate-y-full',
  bottom: 'bottom-0 translate-y-full',
  left: 'left-0 -translate-x-full',
  right: 'right-0 translate-x-full'
};

const topBottomAlignMap: Record<Align, string> = {
  start: 'left-0',
  center: 'left-1/2 -translate-x-1/2',
  end: 'left-full -translate-x-full'
};

const leftRightAlignMap: Record<Align, string> = {
  start: 'top-0',
  center: 'top-1/2 -translate-y-1/2',
  end: 'top-full -translate-y-full'
};

const getPositioning = (position: Position, align: Align) => {
  return `${positionMap[position]} ${
    position === 'top' || position === 'bottom' ? topBottomAlignMap[align] : leftRightAlignMap[align]
  }`;
};

const Tooltip = ({
  children,
  className,
  content: ContentComponent,
  position = 'right',
  align = 'center'
}: React.PropsWithChildren<Props>) => {
  const [open, setOpen] = React.useState(false);

  const onMouseEnter = () => setOpen(true);

  const onMouseLeave = () => setOpen(false);

  return (
    <div className={classnames('relative', className)} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
      {open && (
        <div className={classnames('absolute z-10', getPositioning(position, align))}>
          <ContentComponent />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
