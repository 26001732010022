import { generatePath } from 'react-router';

export const signIn = `/login`;

export const resetPassword = {
  main: `/reset-password`,
  expired: `/reset-password/expired`
};

export const home = `/`;

export const profile = {
  base: `/profile`,
  edit: `/profile/edit`
};

export const jetDeals = {
  base: '/jet-deals',
  create: '/jet-deals/new',
  detail: {
    base: '/jet-deals/:id',
    get: (id: number) => generatePath('/jet-deals/:id', { id })
  }
};

export const statistics = {
  base: `/statistics`
};
