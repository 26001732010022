import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

const MissingDataTooltip = () => {
  return (
    <div className="p-2 rounded-sm bg-black whitespace-nowrap border border-solid border-flyblack-darker-gray">
      <FormattedMessage id={translations.pages.jetDeals.missingInfo} />
    </div>
  );
};

export default MissingDataTooltip;
