import React from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import { translations } from '@/locale';

import Form from '@flyblack/common/components/Form';
import Typography from '@flyblack/common/components/Typography';
import TimeInput from '@flyblack/common/components/Form/Input/TimeInput';
import DatePickerInput from '@flyblack/common/components/Form/Input/DatePickerInput';
import RowContainer from '@flyblack/common/components/RowContainer';

interface Props {
  change: any;
  submitting: any;
  formId: string;
  type: string;
  title: string;
  className?: string;
  disabledDate?: boolean;
  disabledTime?: boolean;
}

const DateTimeInterval: React.FC<Props> = ({
  title,
  submitting,
  change,
  type,
  formId,
  className,
  disabledDate,
  disabledTime
}) => {
  const intl = useIntl();

  return (
    <div className={classnames('flex flex-col', className)}>
      <Typography is="span" type="hummingbird" className="font-normal text-white text-opacity-[32%]">
        {title}
      </Typography>

      <RowContainer className="relative">
        <Typography
          is="span"
          type="halcyon"
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-semibold text-white text-opacity-[32%]"
        >
          -
        </Typography>

        <RowContainer.Item col={6} className="flex">
          <Form.Field
            is={DatePickerInput}
            change={change}
            id={`${formId}-${type}-window-start-date`}
            name={`${type}WindowStartDate`}
            type="text"
            readOnly={submitting}
            className="mr-4"
            inputClassName="rounded-tr-[0px] rounded-br-[0px] border-r-0"
            disabled={disabledDate}
          />

          <Form.Field
            is={TimeInput}
            id={`${formId}-${type}-window-start-time`}
            name={`${type}WindowStartTime`}
            type="text"
            placeholder={intl.formatMessage({ id: translations.inputs.flightWindow.placeholders.windowStartTime })}
            readOnly={submitting}
            className="max-w-[80px]"
            inputClassName="rounded-tl-[0px] rounded-bl-[0px]"
            disabled={disabledTime}
          />
        </RowContainer.Item>

        <RowContainer.Item col={6} className="flex">
          <Form.Field
            is={DatePickerInput}
            change={change}
            id={`${formId}-${type}-window-end-date`}
            name={`${type}WindowEndDate`}
            type="text"
            readOnly={submitting}
            className="mr-4"
            inputClassName="rounded-tr-[0px] rounded-br-[0px] border-r-0"
            disabled={disabledDate}
          />

          <Form.Field
            is={TimeInput}
            id={`${formId}-${type}-window-end-time`}
            name={`${type}WindowEndTime`}
            type="text"
            placeholder={intl.formatMessage({ id: translations.inputs.flightWindow.placeholders.windowEndTime })}
            readOnly={submitting}
            className="max-w-[80px]"
            inputClassName="rounded-tl-[0px] rounded-bl-[0px]"
            disabled={disabledTime}
          />
        </RowContainer.Item>
      </RowContainer>
    </div>
  );
};
export default DateTimeInterval;
