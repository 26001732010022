import React from 'react';
import { FormattedMessage } from 'react-intl';

import { urls } from '@/constants';
import { translations } from '@/locale';

import Link from '@flyblack/common/components/Link';
import Typography from '@flyblack/common/components/Typography';
import HeaderWrapper from '@flyblack/common/components/HeaderWrapper';

import { SessionContext } from '@/components/Context/Session';

export interface Props {}

const Header: React.FC<Props> = () => {
  const { loading, me } = React.useContext(SessionContext);

  return (
    <HeaderWrapper>
      <div className="w-full flex justify-between items-center">
        <div className="flex items-center h-full space-x-8">
          <Typography is={HeaderWrapper.NavItem} to={urls.jetDeals.base} type="halcyon">
            <FormattedMessage id={translations.application.header.navigation.legs} />
          </Typography>
        </div>

        {!loading && me && me.firstName && me.lastName && (
          <Link to={urls.profile.base} className="ml-4 flex items-center md:ml-6">
            <Typography is="span" type="hummingbird" className="mr-6">
              {`${me.firstName} ${me.lastName}`}
            </Typography>
          </Link>
        )}
      </div>
    </HeaderWrapper>
  );
};

export default Header;
