import React from 'react';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { translations } from '@/locale';
import { urls, rules } from '@/constants';
import { getEmptyLegs } from '@/services/api/empty-leg';
import { getRevenue } from '@/services/api/reports';
import usePagination from '@flyblack/common/hooks/usePagination';
import useLoad from '@flyblack/common/hooks/useLoad';
import { By, LegsFilter, Order, Sort, Status } from '@flyblack/common/domains';

import Icon from '@flyblack/common/components/Icon';
import Table from '@flyblack/common/components/Table';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Container from '@flyblack/common/components/Container';
import Typography from '@flyblack/common/components/Typography';
import PageSelector from '@flyblack/common/components/PageSelector';
import SearchByIdInput from '@flyblack/common/components/SearchByIdInput';
import RowContainer from '@flyblack/common/components/RowContainer';

import HasRole from '@/components/HasRole';
import { ActiveDealsCard, BookedDealsCard, HoursFlownCard, RevenueCard } from '@/components/StatisticsCards';

import JetDealTableItem from './JetDealTableItem';
import JetDealTableHeader from './JetDealTableHeader';

export interface Props {}

const JetDeals: React.FC<Props & RouteComponentProps> = ({ history, location }) => {
  const urlFilter = queryString.parse(location.search).type || LegsFilter.All;

  const [selectedFilter, setSelectedFilter] = React.useState(urlFilter ? urlFilter : LegsFilter.All);
  const [searchedId, setSearchedId] = React.useState<number>();

  const { loading, data, page, totalNumberOfPages, loadPage, reload } = usePagination({
    size: 7,
    source: (page, size) =>
      getEmptyLegs(page, size, {
        ...(selectedFilter !== LegsFilter.All
          ? selectedFilter === LegsFilter.Booked
            ? { status: Status.Booked }
            : { status: Status.Open }
          : null),
        [Sort.By]: By.DepartureDate,
        [Sort.Order]: Order.Descendent,
        ...(searchedId ? { ids: [searchedId] } : null)
      })
  });

  React.useEffect(() => {
    !loading && reload();
    selectedFilter !== urlFilter &&
      history.push(queryString.stringifyUrl({ url: urls.jetDeals.base, query: { type: selectedFilter } }));
  }, [selectedFilter]);

  React.useEffect(() => {
    setSelectedFilter(urlFilter);
  }, [urlFilter]);

  React.useEffect(() => {
    reload();
  }, [searchedId]);

  const loadRevenueData = useLoad({
    load: getRevenue
  });

  const onSearchedIdChange = (value: string) => setSearchedId(parseInt(value));

  return (
    <div className="bg-black min-h-full">
      <Spacer xs={3} />

      <Container max>
        <HasRole to={rules.STATISTICS_VIEW}>
          <RowContainer>
            <RowContainer.Item col={8}>
              <RowContainer>
                <RowContainer.Item col={4}>
                  <ActiveDealsCard />
                </RowContainer.Item>

                <RowContainer.Item col={4}>
                  <BookedDealsCard />
                </RowContainer.Item>

                <RowContainer.Item col={4}>
                  <HoursFlownCard />
                </RowContainer.Item>
              </RowContainer>
            </RowContainer.Item>

            <RowContainer.Item col={4}>
              <RevenueCard {...loadRevenueData} />
            </RowContainer.Item>
          </RowContainer>
        </HasRole>

        <div className="flex flex-row justify-between items-center">
          <div className="inline-flex flex-row bg-white bg-opacity-10 p-1 rounded-md">
            {Object.values(LegsFilter).map((item) => (
              <Button
                key={item}
                type="button"
                appearance={selectedFilter === item ? 'white' : 'transparent'}
                className="mx-1 first:mx-0 last:mx-0 w-[160px]"
                onClick={() => setSelectedFilter(item)}
                disabled={loading}
              >
                <Typography is="span" type="halcyon">
                  <FormattedMessage id={translations.domains.legsFilter[item]} />
                </Typography>
              </Button>
            ))}
          </div>

          <div className="flex items-center gap-10">
            <SearchByIdInput id="dealSearch" onChange={onSearchedIdChange} />

            <Button onClick={() => history.push(urls.jetDeals.create)} appearance="white">
              <Icon type="add" className="pr-2" />
              <Typography is="span" type="halcyon">
                <FormattedMessage id={translations.inputs.buttons.addJetDeal} />
              </Typography>
            </Button>
          </div>
        </div>

        <Spacer xs={4} />

        <Table className="min-h-[612px]">
          <JetDealTableHeader />

          <Table.Body>
            {!loading && data.map((item) => <JetDealTableItem key={item.id} data={item} onAction={reload} />)}
          </Table.Body>
        </Table>

        <Spacer xs={3} />

        <PageSelector pages={totalNumberOfPages} selected={page} onPageChange={loadPage} />
      </Container>
    </div>
  );
};

export default JetDeals;
