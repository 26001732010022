import { Role } from '@/constants';

export interface VendorMe {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  vendor?: {
    name?: string;
    address?: string;
    paymentInfo: {
      account?: string;
      bankName?: string;
    };
  };
  profileCompletionStatus?: ProfileCompletionStatus;
  role?: Role;
}

export enum ProfileCompletionStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}
