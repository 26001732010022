import { Role, rules } from '@/constants';

const vendorRules = [rules.STATISTICS_VIEW];

const permissionMap = {
  [Role.Vendor]: vendorRules
};

export const hasRole = (role: Role, rule: string) => {
  if (!role) {
    return false;
  }

  const permission = permissionMap[role];

  if (!permission) {
    return false;
  }

  if (permission.indexOf(rule) !== -1) {
    return true;
  }

  return false;
};
