import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locale';

import Table from '@flyblack/common/components/Table';
import Typography from '@flyblack/common/components/Typography';

export interface Props {}

const JetDealTableHeader: React.FC<Props> = () => {
  const text = translations.pages.jetDeals.table.header;

  return (
    <Typography is={Table.Header} type="hummingbird" className="uppercase text-opacity-[64%] justify-between font-bold">
      <Table.Data className="w-[8%]" header>
        <FormattedMessage id={text.status} />
      </Table.Data>

      <Table.Data className="w-[10%]" header>
        <FormattedMessage id={text.departure} />
      </Table.Data>

      <Table.Data className="w-[22%]" header>
        <FormattedMessage id={text.departureTimes} />
      </Table.Data>

      <Table.Data className="w-[10%]" header>
        <FormattedMessage id={text.arrival} />
      </Table.Data>

      <Table.Data className="w-[22%]" header>
        <FormattedMessage id={text.arrivalTimes} />
      </Table.Data>

      <Table.Data className="w-[8%]" header>
        <FormattedMessage id={text.category} />
      </Table.Data>

      <Table.Data className="w-[10%]" header>
        <FormattedMessage id={text.type} />
      </Table.Data>

      <Table.Data className="w-[5%] justify-end" header>
        <FormattedMessage id={text.price} />
      </Table.Data>

      <Table.Data className="w-[5%]" header></Table.Data>
    </Typography>
  );
};

export default JetDealTableHeader;
