import React from 'react';
import { Field } from 'react-final-form';

export interface Props {
  when: string;
  is: any;
  children: any;
}

const Condition: React.FC<Props> = ({ when, is, children }) => {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>
  );
};

export default Condition;
