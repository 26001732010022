import React from 'react';
import * as yup from 'yup';

import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { SignIn } from '@flyblack/common/domains';
import { STRING_LONG_MAX_LENGTH, urls } from '@/constants';

import { useQuery } from '@flyblack/common/hooks/useQuery';
import Form from '@flyblack/common/components/Form';
import Button from '@flyblack/common/components/Button';
import Spacer from '@flyblack/common/components/Spacer';
import { SessionConsumer } from '@/components/Context/Session';
import { SubmitError } from '@flyblack/common/components/Error';
import TextInput from '@flyblack/common/components/Form/Input/TextInput';
import { withValidation } from '@flyblack/common/components/hoc/withValidation';

const TextInputWithValidation = withValidation(TextInput);

const schema = yup.object({
  email: yup
    .string()
    .email()
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.email.label)
    .required(),
  password: yup
    .string()
    .min(8)
    .max(STRING_LONG_MAX_LENGTH)
    .label(translations.inputs.password.label)
    .required()
});

const SignInPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const query = useQuery();

  const resetPasswordMessage = !!query.get('reset-password');

  return (
    <SessionConsumer>
      {({ signInWithEmail }) => (
        <div className="flex flex-col sm:max-w-md w-[320px] px-5 sm:px-0">
          <div className="uppercase text-xs tracking-[2px] font-semibold">
            <FormattedMessage id={translations.pages.signIn.title} />
          </div>

          <Form
            schema={schema}
            subscription={{ submitError: true, submitting: true, values: true, hasValidationErrors: true, dirty: true }}
            onSubmit={(values: SignIn) =>
              signInWithEmail(values.email, values.password).then(() => history.push(urls.jetDeals.base))
            }
          >
            {({ submitError, submitting, values, hasValidationErrors, dirty }) => (
              <div className="flex flex-col">
                <Spacer xs={6} />

                {resetPasswordMessage && (
                  <div className="text-sm font-normal text-flyblack-light-gray">
                    <FormattedMessage id={translations.pages.signIn.subtitle} />
                  </div>
                )}

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  id="email"
                  name="email"
                  type="text"
                  leadingIcon="email"
                  placeholder={intl.formatMessage({ id: translations.inputs.email.placeholder })}
                />

                <Spacer xs={4} />

                <Form.Field
                  is={TextInputWithValidation}
                  id="password"
                  name="password"
                  type="password"
                  leadingIcon="password"
                  placeholder={intl.formatMessage({ id: translations.inputs.password.placeholder })}
                />

                <Spacer xs={6} />

                {!!submitError && (
                  <React.Fragment>
                    <SubmitError error={submitError} />

                    <Spacer xs={2} />
                  </React.Fragment>
                )}

                <Button
                  fat
                  type="submit"
                  className="font-medium"
                  appearance="white"
                  disabled={hasValidationErrors || !dirty || submitting}
                >
                  <FormattedMessage id={translations.pages.signIn.logInButton} />
                </Button>
              </div>
            )}
          </Form>
        </div>
      )}
    </SessionConsumer>
  );
};

export default SignInPage;
