import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import format from 'date-fns/format';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData
} from 'chart.js';

import { translations } from '@/locale';
import { chartOptions } from '@flyblack/common/constants';
import { RevenueReport } from '@flyblack/common/domains';

import { Return as LoadReturn } from '@flyblack/common/hooks/useLoad';

import Loading from '@flyblack/common/components/Loading';
import DetailCard from '@flyblack/common/components/DetailCard';
import Typography from '@flyblack/common/components/Typography';

import gradientImg from '@flyblack/common/assets/gradient.png';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const RevenueChartCard = ({ value: revenueReport, loading, error }: LoadReturn<RevenueReport>) => {
  const intl = useIntl();

  const [chartData, setChartData] = React.useState<ChartData<'line'>>();

  React.useEffect(() => {
    if (revenueReport) {
      setChartData({
        labels: revenueReport.periods.map(({ year, periodOfYear }) => format(new Date(year, periodOfYear - 1), 'LLL')),
        datasets: [
          {
            label: intl.formatMessage({ id: translations.pages.jetDeals.reports.revenueChart.labels.deals }),
            data: revenueReport.emptyLegsRevenue.data,
            borderColor: '#4F3F9E',
            backgroundColor: '#4F3F9E'
          }
        ]
      });
    }
  }, [revenueReport]);

  return (
    <DetailCard
      style={{ backgroundImage: `url(${gradientImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <Typography is="div" type="halcyon" className="mb-6 font-semibold">
        <FormattedMessage id={translations.pages.jetDeals.reports.revenueChart.title} />
      </Typography>
      {loading ? (
        <Loading visible={loading} className="py-9" center>
          <Loading.Indicator size={40} borderWidth={2} />
        </Loading>
      ) : error ? (
        <FormattedMessage id={translations.pages.jetDeals.reports.errorValue} />
      ) : (
        chartData && (
          <div className="h-[350px]">
            <Line options={chartOptions} data={chartData} />
          </div>
        )
      )}
    </DetailCard>
  );
};

export default RevenueChartCard;
