import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { VendorMe } from '@/domains/me';
import { Session } from '@flyblack/common/domains';

import { urls } from '@/constants';
import { getMe } from '@/services/api/me';
import { storage } from '@/services/storage';
import { authorizedApi } from '@/services/network/authorized-api';
import { signInWithEmail, renewToken } from '@/services/api/session';

interface SessionActions {
  reload(): Promise<null>;
  signInWithEmail(email: string, password: string): Promise<null>;
  signOut(): Promise<null>;
}

interface SessionState {
  session: Session | null;
  me: VendorMe | null;
  loading: boolean;
  error: any;
}

export const SessionContext = React.createContext<SessionActions & SessionState>({} as any);

interface Props {}

interface State extends Readonly<SessionActions>, Readonly<SessionState> {}

export const SessionConsumer = SessionContext.Consumer;

class Provider extends React.PureComponent<Props & RouteComponentProps, State> {
  wrapWithSession(callback: (...values: any[]) => Promise<Session>) {
    return (...values: any[]) =>
      callback(...values)
        .then(this.updateSession)
        .then(this.loadMe)
        .then(() => null);
  }

  signOut = () => Promise.resolve().then(this.clearSession);

  state: State = {
    session: null,
    me: null,
    loading: true,
    error: null,
    reload: () => this.loadMe(),
    signInWithEmail: this.wrapWithSession(signInWithEmail),
    signOut: this.signOut
  };

  componentDidMount() {
    authorizedApi.configure({
      onUnauthorized: () => Promise.resolve().then(this.clearSession),
      onAccessTokenExpired: this.renewToken
    });

    const session = storage.getItem<Session | null>('session');

    if (!session) return this.setState({ loading: false });

    this.updateSession(session)
      .then(this.loadMe)
      .catch(() => this.setState({ loading: false }));
  }

  updateSession = (session: Session) => {
    return new Promise((resolve) =>
      this.setState({ session }, () => {
        this.onSessionUpdated();
        resolve(null);
      })
    );
  };

  clearSession = () => {
    this.setState({ session: null, me: null, loading: false }, this.onSessionUpdated);
    return null;
  };

  loadMe = () =>
    getMe()
      .then(this.updateMe)
      .catch(this.clearSession);

  updateMe = (me: VendorMe) => {
    this.setState({ me, loading: false });
    return null;
  };

  renewToken = () => {
    if (!this.state.session || !this.state.session.refreshToken)
      return Promise.reject(new Error(`No session found. Signing out...`));

    return renewToken(this.state.session.refreshToken)
      .then(this.updateSession)
      .catch(() => {
        this.clearSession();
        this.props.history.push(urls.home);
      });
  };

  onSessionUpdated = () => {
    storage.setItem('session', this.state.session);

    authorizedApi.token = this.state.session ? this.state.session.accessToken : null;
  };

  render() {
    return <SessionContext.Provider value={this.state}>{this.props.children}</SessionContext.Provider>;
  }
}

export const SessionProvider = withRouter(Provider);

export default {
  SessionProvider,
  SessionConsumer,
  SessionContext
};
